import { Box, Button, Grid, InfiniteScroll, Layer } from "grommet";
import { Add, Trash } from "grommet-icons";
import React from "react";
import CatalogHeader from "../../../../components/catalog/catalog_header";
import { PLATFORM } from "../../../../components/catalog/utils";
import NewItemPopup from "../../../../components/common/new_item";
import PopupConfirmation from "../../../../components/elements/confirmation_popup";
import CustomMenu from "../../../../components/elements/custom_menu";
import { EventEmitter } from "../../../../components/elements/event_emitter";
import PrimaryButton from "../../../../components/elements/primary_button";
import LoadingAnimation from "../../../../components/loading_animation";
import NavigationFrame from "../../../../components/nav_frame";
import OptionListView from "../../../../components/option/option-list_view";
import OptionListsView from "../../../../components/option/option-lists_view";
import catalogService from "../../../../service/catalog_service";
import optionService from "../../../../service/option_service";
import storageService from "../../../../service/storage_service"
import utils from "../../../../service/utils";

class OptionLists extends React.Component {
  constructor(props) {
    super(props);
    const { location } = props;

    this.state = {
      contextData: location.state || {},
      catalogId: "",
      selectedOptionList: undefined,
      newOptionList: undefined,
      loading: false,
    };

    storageService.clearState();
  }

  componentDidMount = () => {
    const catalogId = utils.getIdAfter("catalogs/");
    this.setState({ catalogId });
    console.log("did mount: ", this.state.contextData);
    if (this.state.contextData.catalog) {
      const list_id = utils.getParamUrl("id");
      if (list_id && this.state.contextData.option_lists) {
        const selected_item = this.state.contextData.option_lists.find(
          (ol) => ol.id === parseInt(list_id)
        );
        this.setState({ selectedOptionList: selected_item });
      }
      return;
    }
    this.reloadData(catalogId);
  };

  reloadData = (catalogId) => {
    this.setState({ loading: "Loading catalog..." });
    catalogService
      .getCatalogData(catalogId)
      .then((res) => {
        const contextData = utils.createContextData(res.data);
        this.setState({ contextData, loading: false });
      })
      .catch(EventEmitter.errorHandler);
  };

  onOptionListChange = (field, value) => {
    const optionList = this.state.selectedOptionList;
    optionList[field] = value;
    optionList.need_update = true;
    const contextData = utils.updateContextData(this.state.contextData, {
      option_list: optionList,
    });
    this.setState({ selectedOptionList: { ...optionList }, contextData });
  };

  saveItem = async () => {
    const isPublishable = await this.checkCatalogPublishable();
    if (!isPublishable) {
      EventEmitter.dispatch("showMessage", {
        message:
          "You need to wait for at least one minute to pushish to Deliveroo/Uber Eat",
        messageType: "warning",
      });
      return;
    }
    this.setState({ loading: "Saving catalog..." });

    const { error } = await catalogService.saveAllCatalogs(
      this.state.contextData
    );
    if (error) {
      EventEmitter.dispatch("showMessage", {
        message: error,
        messageType: "error",
      });
      return;
    }
    await this.pushCatalog();
    this.reloadData(this.state.catalogId);
  };

  pushCatalog = async () => {
    this.setState({ loading: "Pushing catalog..." });
    await catalogService
      .pushCatalog(this.state.catalogId)
      .then((res) => {
        const result = res.data.result || [];
        const isUberOK = result.find(
          (r) => r.platform === PLATFORM.UBER_EAT && r.status === 200
        );
        console.log(result, isUberOK);
        if (isUberOK) {
          EventEmitter.dispatch("showMessage", {
            message: "The catalog is pushed to UberEat successfully",
          });
        } else {
          EventEmitter.dispatch("showMessage", {
            message: "Cannot push the menu to Uber Eat",
            messageType: "error",
          });
        }
        const isDeliverooOK = result.find(
          (r) => r.platform === PLATFORM.DELIVEROO && r.status === 200
        );
        if (isDeliverooOK) {
          EventEmitter.dispatch("showMessage", {
            message: "The catalog is pushed to Deliveroo successfully",
          });
        } else {
          EventEmitter.dispatch("showMessage", {
            message: "Cannot push the menu to Deliveroo",
            messageType: "error",
          });
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err.response?.data);
        const message =
          err.response?.data?.message ||
          "There is a problem when pushing catalog, nothing done";
        EventEmitter.dispatch("showMessage", { message, messageType: "error" });
        this.setState({ loading: false });
      });
  };

  checkCatalogPublishable = async (_) => {
    this.setState({ loading: "Checking pushable..." });
    let result;
    try {
      const res = await catalogService.checkPublishable(this.state.catalogId);
      result = res.data.pubishable;
    } catch (error) {
      result = false;
    }
    this.setState({ loading: false });
    return result;
  };

  createOptionList = (optionList) => {
    this.setState({ loading: "Creating an option list ..." });
    optionList.catalog_id = this.state.catalogId;
    optionService
      .saveOptionList(optionList)
      .then((res) => {
        const contextData = utils.updateContextData(this.state.contextData, {
          option_list: res.data.data,
        });
        this.setState({
          loading: false,
          newOptionList: undefined,
          option_lists: contextData.option_lists,
          contextData,
          selectedOptionList: contextData.selected_item,
        });
        EventEmitter.dispatch("showMessage", {
          message: "Option list is created successfully!",
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        EventEmitter.errorHandler(err);
      });
  };

  deleteItem = async () => {
    this.setState({ loading: "Deleting..." });
    let errMess = "";
    let contextData = this.state.contextData;
    const option_list = this.state.selectedOptionList;
    try {
      await optionService.deleteOptionList(option_list.id);
      contextData = utils.removeItemFromContext(contextData, { option_list });
    } catch (error) {
      errMess = error.response?.data?.message || "Unexpected error";
    }
    this.setState({
      loading: false,
      deleteConfirmation: false,
      contextData,
      selectedOptionList: null,
    });
    if (errMess) {
      EventEmitter.dispatch("showMessage", {
        message: "Unexpected error",
        messageType: "error",
      });
    } else {
      EventEmitter.dispatch("showMessage", {
        message: "Option list is deleted successfully!",
      });
    }
  };

  deleteOption = async (opt) => {
    this.setState({ loading: "Deleting ..." });
    optionService
      .deleteOption(opt.id)
      .then((_) => {
        EventEmitter.dispatch("showMessage", {
          message: "Option is deleted successfully!",
        });
        const optionList = this.state.selectedOptionList;
        optionList.options = optionList.options.filter((o) => o.id !== opt.id);
        const contextData = utils.updateContextData(this.state.contextData, {
          option_list: optionList,
        });
        this.setState({
          loading: false,
          option_lists: contextData.option_lists,
          selectedOptionList: contextData.selected_item,
          contextData,
        });
      })
      .catch((err) => {
        EventEmitter.errorHandler(err);
        this.setState({ loading: false });
      });
  };

  render() {
    return (
      <NavigationFrame>
        <Box
          pad="medium"
          style={{ height: "100vh" }}
          width="xxlarge"
          gap="medium"
        >
          <Grid
            fill
            rows={["auto", "flex"]}
            columns={["auto", "flex"]}
            areas={[
              { name: "header", start: [0, 0], end: [1, 0] },
              { name: "sidebar", start: [0, 1], end: [0, 1] },
              { name: "main", start: [1, 1], end: [1, 1] },
            ]}
          >
            <Box
              gridArea="header"
              justify="center"
              align="center"
              width={"full"}
            >
              <CatalogHeader catalog={this.state.contextData.catalog} />
              <Box
                direction="row"
                align="center"
                width={"full"}
                background="#F8F8F8"
                round="small"
                justify="between"
                pad={{ vertical: "small" }}
                margin={{ bottom: "large" }}
              >
                <Box align="center" gap="small" direction="row">
                  <Button
                    icon={<Add size="small" />}
                    size="small"
                    label="New option list"
                    onClick={() => this.setState({ newOptionList: {} })}
                  />
                  <Button
                    icon={<Trash size="small" />}
                    size="small"
                    label="Delete"
                    disabled={this.state.selectedOptionList ? false : true}
                    onClick={() => this.setState({ deleteConfirmation: true })}
                  />
                </Box>
                <Box align="center">
                  <PrimaryButton
                    label="Save"
                    disabled={this.state.selectedOptionList ? false : true}
                    onClick={this.saveItem}
                  />
                </Box>
              </Box>
            </Box>
            <Box gridArea="sidebar" width={"small"}>
              <CustomMenu menu_data={this.state.contextData} />
            </Box>

            <Box
              gridArea="main"
              width={"full"}
              margin={{ left: "medium" }}
              pad="small"
              direction="row"
              gap="medium"
            >
              {!this.state.contextData.option_lists ? (
                <LoadingAnimation text={this.state.loading} />
              ) : (
                <Box
                  width={"medium"}
                  style={{ overflowY: "auto", minHeight: "auto" }}
                >
                  <InfiniteScroll items={this.state.contextData.option_lists}>
                    {(optionList) => (
                      <OptionListsView
                        key={optionList.name}
                        optionList={optionList}
                        onOptionListSelected={(ol) =>
                          this.setState({ selectedOptionList: ol })
                        }
                        selectedOptionList={this.state.selectedOptionList}
                      />
                    )}
                  </InfiniteScroll>
                </Box>
              )}

              {this.state.selectedOptionList ? (
                <OptionListView
                  optionList={this.state.selectedOptionList}
                  onOptionListChange={this.onOptionListChange}
                  deleteOption={this.deleteOption}
                />
              ) : null}
            </Box>
          </Grid>
          {this.state.newOptionList ? (
            <NewItemPopup
              label={"New option list"}
              onClose={() => this.setState({ newOptionList: undefined })}
              onCreate={this.createOptionList}
            />
          ) : null}
          {this.state.deleteConfirmation ? (
            <PopupConfirmation
              message={"Are you sure to delete this item?"}
              primaryAction={() => this.deleteItem()}
              primaryActionText="Delete"
              primaryActionIcon={<Trash color="#fff" />}
              secondaryActionText="Cancel"
              secondaryAction={() =>
                this.setState({ deleteConfirmation: false })
              }
            />
          ) : null}
          {this.state.loading ? (
            <Layer>
              <Box
                justify="center"
                align="center"
                style={{ width: 300, height: 300 }}
              >
                <LoadingAnimation text={this.state.loading} />
              </Box>
            </Layer>
          ) : null}
        </Box>
      </NavigationFrame>
    );
  }
}

export default OptionLists;
