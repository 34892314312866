const axios = require('axios');
const { baseAPI } = require('../config/config.json');
const { getToken } = require('./storage_service');

const buildHeader = () => {
    return ({
        'Authorization': `Bearer ` + getToken(),
    })
}
module.exports = {
    getOptionLists: (catalogId) => {
        const params = {
            "filters[$and][0][catalog_id][$eq]": catalogId,
        }
        return axios.get(`${baseAPI}/api/option-lists?populate=deep,2`, { headers: buildHeader(), params })
    },
    saveOptionList: optionList => {
        const body = { id: optionList.id, data: optionList };
        return optionList.id ?
            axios.put(`${baseAPI}/api/option-lists/${optionList.id}?populate=*`, body, { headers: buildHeader() }) :
            axios.post(`${baseAPI}/api/option-lists`, body, { headers: buildHeader() });
    },
    deleteOptionList: (id) => axios.delete(`${baseAPI}/api/custom/option-lists/${id}`, { headers: buildHeader() }),
    saveOption: option => {
        if(isNaN(option.id)) delete option.id;
        const body = { id: option.id, data: option };
        return option.id ?
            axios.put(`${baseAPI}/api/options/${option.id}`, body, { headers: buildHeader() }) :
            axios.post(`${baseAPI}/api/options`, body, { headers: buildHeader() });
    },
    deleteOption: (id) => axios.delete(`${baseAPI}/api/options/${id}`, { headers: buildHeader() }),
}