const axios = require('axios');
const { baseAPI } = require('../config/config.json');
const { getToken } = require('./storage_service');

const buildHeader = () => {
    return ({
        'Authorization': `Bearer ` + getToken(),
    })
}
module.exports = {
    getProduct: (id) => axios.get(`${baseAPI}/api/products/${id}?populate=deep,4`, { headers: buildHeader() }),

    deleteProduct: (id) => axios.delete(`${baseAPI}/api/products/${id}`, { headers: buildHeader() }),

    saveProduct: product => {
        const body = { id: product.id, data: product };
        return product.id ?
            axios.put(`${baseAPI}/api/products/${product.id}`, body, { headers: buildHeader() }) :
            axios.post(`${baseAPI}/api/products?populate=*`, body, { headers: buildHeader() });
    },

    getCatalogData: (catalogId) => {
        return axios.get(`${baseAPI}/api/catalogs-data/${catalogId}`, { headers: buildHeader() })
    },

    saveCategory: category => {
        const body = { id: category.id, data: category };
        return category.id ?
            axios.put(`${baseAPI}/api/categories/${category.id}`, body, { headers: buildHeader() }) :
            axios.post(`${baseAPI}/api/categories`, body, { headers: buildHeader() });
    },

    saveSku: (sku) => {
        const body = { id: sku.id, data: sku }
        return axios.put(`${baseAPI}/api/skus/${sku.id}`, body, { headers: buildHeader() })
    },
    deleteCategory: (id) => axios.delete(`${baseAPI}/api/custom/categories/${id}`, { headers: buildHeader() }),
}