const axios = require("axios");
const { baseAPI } = require("../config/config.json");
const { getToken } = require("./storage_service");
const productService = require("./category_service");
const optionService = require("./option_service");

const buildHeader = () => {
  return {
    Authorization: `Bearer ` + getToken(),
  };
};
const findDup = (arr) => {
  if (!arr) return;
  const hasId = arr.some((a) => a.id);
  if (hasId) {
    arr = arr.map((a) => a.id);
  }
  const obj = {};
  arr.forEach((e) => (obj[e] = (obj[e] || 0) + 1));
  return Object.keys(obj).find((key) => obj[key] > 2);
};
module.exports = {
  getCatalog: (id) => {
    return axios.get(`${baseAPI}/api/catalogs/${id}?populate=*`, {
      headers: buildHeader(),
    });
  },
  searchCatalog: ({ query, partnerId, page }) => {
    const params = { sort: "name:ASC" };
    if (query) params._q = query;
    if (partnerId) params["filters[$and][0][partner][id][$eq]"] = partnerId;
    if (page) {
      params["pagination[page]"] = page;
    }
    params["fields[0]"] = "id";
    params["fields[1]"] = "name";
    params["fields[2]"] = "partner_name";
    return axios.get(`${baseAPI}/api/catalogs`, {
      headers: buildHeader(),
      params,
    });
  },
  saveCatalog: (catalog) => {
    const body = { id: catalog.id, data: catalog };
    return catalog.id
      ? axios.put(`${baseAPI}/api/catalogs/${catalog.id}?populate=*`, body, {
          headers: buildHeader(),
        })
      : axios.post(`${baseAPI}/api/catalogs?populate=*`, body, {
          headers: buildHeader(),
        });
  },
  cascadeDeleteCatalog: (id) => {
    return axios.delete(`${baseAPI}/api/catalogs/cascade-delete/${id}`, {
      headers: buildHeader(),
    });
  },
  pullCatalog: (id, platform) => {
    const params = { platform };
    return axios.get(`${baseAPI}/api/catalogs/${id}/pull-menu`, {
      headers: buildHeader(),
      params,
    });
  },
  pushCatalog: (id, platform) => {
    return axios.post(
      `${baseAPI}/api/catalogs/${id}/push-menu`,
      {},
      { headers: buildHeader(), params: { platform } }
    );
  },
  updateEnventory: (id, platform) => {
    return axios.post(
      `${baseAPI}/api/catalogs/${id}/inventory`,
      {},
      { headers: buildHeader(), params: { platform } }
    );
  },
  duplicateCatalog: (id, body) => {
    return axios.post(`${baseAPI}/api/catalogs/${id}/duplicate`, body, {
      headers: buildHeader(),
    });
  },
  checkPublishable: (id) => {
    return axios.get(`${baseAPI}/api/catalogs/${id}/publishable`, {
      headers: buildHeader(),
    });
  },
  checkExistingId: (catalog, platform) => {
    return axios.post(
      `${baseAPI}/api/catalogs/exists`,
      { catalog, platform },
      { headers: buildHeader() }
    );
  },
  getCatalogData: (catalogId) => {
    return axios.get(`${baseAPI}/api/catalogs-data/${catalogId}`, {
      headers: buildHeader(),
    });
  },
  saveAllCatalogs: async ({ categories, option_lists }) => {
    // validation
    for (const category of categories) {
      for (const product of category.products) {
        const sku = product.skus[0];
        if(!sku) continue;
        sku.option_list_uids = (sku?.option_lists || []).map(
          (o) => o.option_list_uid
        );
        let dup = findDup(sku.option_list_uids);
        if (dup) {
          return {
            error: `Product ${product.name} has duplicate ${dup} in option list`,
          };
        }
      }
      let dup = findDup(category.products.map((p) => p.product_uid));
      if (dup) {
        return {
          error: `Product ${dup} is duplicated`,
        };
      }
    }
    // save all products - categories
    for (const category of categories) {
      for (const product of category.products) {
        if (!product.need_update) continue;
        for (const sku of product.skus) {
          sku.option_list_uids = (sku.option_lists || []).map(
            (o) => o.option_list_uid
          );
          await productService.saveSku(sku);
        }
        // save product info only
        delete product.skus;
        await productService.saveProduct(product);
      }
      if (category.need_update) {
        await productService.saveCategory(category);
      }
    }

    // save options - option_lists
    for (const option_list of option_lists) {
      if (!option_list.need_update) continue;
      for (const option of option_list.options) {
        option.option_list = option_list.id;
        await optionService.saveOption(option);
      }
      // save option list info only
      delete option_list.options;
      await optionService.saveOptionList(option_list);
    }
    return { sucess: true };
  },
  getDeliverooBrandId: (site_id) => {
    return axios.get(`${baseAPI}/api/catalogs/${site_id}/deliveroo-brand`, {
      headers: buildHeader(),
    });
  },
};
