import { Box } from "grommet";
import React from "react";
import StandardText, { COLOR } from "../elements/standard_text";


const OptionListsView = ({ optionList, onOptionListSelected, selectedOptionList }) => {

    const isSelected = optionList.id === selectedOptionList?.id;

    return (
        <Box width={'full'} background='#fff' style={{ minHeight: 'auto' }} margin={{ bottom: 'xsmall' }}>
            <Box width={'full'} style={{ minHeight: 'auto' }}>
                <Box round={{ corner: 'top', size: 'xsmall' }}
                    pad='small'
                    style={{ boxShadow: 'none' }}
                    onClick={() => onOptionListSelected(optionList)}
                    width='full' background={isSelected ? 'brand' : '#fff'} border={{ color: '#F2F2F2' }} >
                    <StandardText label={optionList.name} size='medium'
                        color={isSelected ? '#fff' : COLOR.black}
                    />
                    <StandardText label={optionList.option_list_uid} size='xsmall'
                        color={isSelected ? '#fff' : COLOR.black}
                    />
                </Box>

            </Box>

        </Box>
    )
}
export default OptionListsView;