import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "grommet";
import { Add, Close, FormDown, FormUp, Trash } from "grommet-icons";
import React, { useState } from "react";
import CustomInput, { PlaceHolderInput } from "../elements/custom_input";
import StandardText, { COLOR } from "../elements/standard_text";
import PopupConfirmation from "../elements/confirmation_popup";
import { genUid } from "../../service/model_utils";

const OptionListView = ({
  optionList,
  onOptionListChange,
  deleteOption,
}) => {
  const [optionToDelete, setDeleteOption] = useState();

  const onOptionChange = (optionId, field, value) => {
    const option = optionList.options.find((o) => o.id === optionId);
    option[field] = value;
    onOptionListChange(
      "options",
      optionList.options.map((o) => o)
    );
  };

  const addNewOption = () => {
    const options = optionList.options || [];
    // string id for delete after
    const customId = `xxx ${Math.random().toString().substring(3, 10)}`;
    options.push({ id: customId, position: options.length });
    onOptionListChange("options", options);
  };

  const onOptionMove = (currOption, direction) => {
    const options = optionList.options;
    const currIdx = options.map((p) => p.id).indexOf(currOption.id);
    const nextIdx = currIdx + direction;
    if (nextIdx < 0 || nextIdx >= options.length) return;

    const nextOption = options[nextIdx];
    nextOption.need_update = true;
    currOption.need_update = true;

    // swap two position
    const tmpPosition = currOption.position;
    currOption.position = nextOption.position;
    nextOption.position = tmpPosition;

    // swap inside category
    options[nextIdx] = currOption;
    options[currIdx] = nextOption;
    onOptionListChange(
      "options",
      options.map((o) => o)
    );
  };

  return (
    <Box
      width={"full"}
      pad={{ horizontal: "medium", vertical: "small" }}
      round={{ size: "xsmall" }}
      margin={{ bottom: "medium" }}
      style={{ overflowY: "auto" }}
      background="#fff"
    >
      <Box gap="medium" style={{ minHeight: "auto" }}>
        <CustomInput
          value={optionList.name}
          onChange={(event) => onOptionListChange("name", event.target.value)}
        />
        <Box
          gap="small"
          margin={{ horizontal: "small" }}
          justify="between"
          direction="row"
        >
          <Box>
            <StandardText
              label={"Min. selections"}
              margin={{ horizontal: "small" }}
              color="#999"
            />
            <CustomInput
              size="small"
              value={optionList.min_selections}
              onChange={(event) =>
                onOptionListChange("min_selections", event.target.value)
              }
            />
          </Box>
          <Box>
            <StandardText
              label={"Max. selections"}
              margin={{ horizontal: "small" }}
              color="#999"
            />
            <CustomInput
              size="small"
              value={optionList.max_selections}
              onChange={(event) =>
                onOptionListChange("max_selections", event.target.value)
              }
            />
          </Box>
        </Box>
        <Box style={{ minHeight: "auto" }} margin={{ horizontal: "small" }}>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell scope="col">
                  <StandardText label={"Name"} color="#999" />
                </TableCell>
                <TableCell scope="col">
                  <StandardText label={"Price"} color="#999" />
                </TableCell>
                <TableCell scope="col">
                  <StandardText label={"Ref code"} color="#999" />
                </TableCell>
                <TableCell scope="col">
                  <StandardText label={"Stock"} color="#999" />
                </TableCell>
                <TableCell scope="col" width={"90px"}></TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {optionList.options.map((opt) => (
                <TableRow key={opt.id} style={{ cursor: "pointer" }}>
                  <TableCell>
                    <CustomInput
                      value={opt.name}
                      size="small"
                      onChange={(event) => {
                        onOptionChange(opt.id, "name", event.target.value);
                        setTimeout(() => {
                          if((opt.id+"").includes('xxx')) {
                            onOptionChange(opt.id, "option_uid", genUid(event.target.value));
                          }
                        }, 500);
                      }}
                    />
                  </TableCell>
                  <TableCell width={'90px'}>
                    <PlaceHolderInput
                      placeholder={"€"}
                      value={opt.price?.replace(" EUR", "").replace(",", ".")}
                      size="small"
                      onChange={(event) =>
                        onOptionChange(
                          opt.id,
                          "price",
                          event.target.value + " EUR"
                        )
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <CustomInput
                      value={opt.option_uid}
                      size="small"
                      onChange={(event) =>
                        onOptionChange(opt.id, "option_uid", event.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell width={'90px'}>
                    <CustomInput
                      value={opt.stock}
                      size="small"
                      onChange={(event) =>
                        onOptionChange(opt.id, "stock", event.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell width={"90px"}>
                    <Box direction="row" gap="xsmall">
                      <FormDown
                        style={{ width: 18, height: 18 }}
                        color={COLOR.black}
                        onClick={(e) => {
                          e.stopPropagation();
                          onOptionMove(opt, 1);
                        }}
                      />
                      <FormUp
                        style={{ width: 18, height: 18 }}
                        color={COLOR.black}
                        onClick={(e) => {
                          e.stopPropagation();
                          onOptionMove(opt, -1);
                        }}
                      />
                    </Box>
                  </TableCell>
                  <TableCell width={"90px"}>
                    <Button
                      icon={
                        isNaN(opt.id) ? (
                          <Close color={COLOR.error} />
                        ) : (
                          <Trash color={COLOR.error} />
                        )
                      }
                      onClick={() => {
                        console.log(opt.id, isNaN(opt.id));
                        if (isNaN(opt.id)) {
                          onOptionListChange(
                            "options",
                            optionList.options.filter((o) => o.id !== opt.id)
                          );
                        } else {
                          setDeleteOption(opt);
                        }
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
              <TableRow key={"xx"}>
                <TableCell>
                  <Button
                    icon={<Add size="small" />}
                    size="xsmall"
                    label="New option"
                    onClick={addNewOption}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Box>
      {optionToDelete ? (
        <PopupConfirmation
          close={() => setDeleteOption()}
          secondaryActionText={"No"}
          secondaryAction={() => setDeleteOption()}
          message={"Do you really to delete this option?"}
          primaryAction={() => {
            deleteOption(optionToDelete);
            setDeleteOption();
          }}
          primaryActionText={"Yes"}
        />
      ) : null}
    </Box>
  );
};

export default OptionListView;
